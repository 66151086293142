/**
 * When noScroll is set (mostly done by modals) we don't scroll to the top of our page.
 */
exports.shouldUpdateScroll = ({ routerProps, prevRouterProps = {} }) => {
  const { location } = routerProps;
  const { location: prevLocation } = prevRouterProps;

  return !(
    (location && location.state && location.state.noScroll) ||
    (prevLocation && prevLocation.state && prevLocation.state.noScroll)
  );
};
